.App {
  font-family: "Roboto", sans-serif;
  color: #f9f9f9;
  font-weight: 500;
  background-color: #212f41;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

.sidebar {
  width: 3.1vw;
  height: 93.9vh;
  background-color: #1c293b;
  display: inline-block;
  float: left;
}

.mainPage {
  width: 100vw;
  height: 93.9vh;
  display: inline-block;
  float: left;
}

.renderWindow {
  width: 96vw;
  height: 93.9vh;
  display: inline-block;
}

.headerWrapper {
  z-index: 3;
  margin-top: 0.5%;
  display: inline-block;
  position: absolute;
  width: 100vw;
  height: 6vh;
  left: 0;
}

.settingsCog {
  position: absolute;
  right: 0;
  float: left;
  font-size: 20pt;
  width: 3%;
  display: inline-block;
}

.currentUserLogin {
  text-align: center;
  position: absolute;
  left: 3.1vw;
  font-size: 13pt;
  padding-top: 0.7vh;
  font-weight: 300;
  width: 97vw;
  display: inline-block;
  float: left;
}

.linkWrapper {
  padding-top: 25px;
  /* display:inline-block; */
}
.linkWrapper2 {
  padding-top: 36vh;
  /* display:inline-block; */
}

.loginWrapper {
  height: 100vh;
  overflow-y: hidden;
}

.loginBG {
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.2;
  top: 0;
}

.loginBG.mobile {
  width: 300vw;
  margin-right: 200%;
}

.loginLogo {
  z-index: 1000;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20vh;
  margin-top: 5%;
}

.firebaseAuthWrapper {
  display: block;
  margin-top: 10%;
  opacity: 0.8; /* For å gi lik opacity som logoen */
}

.linkTo {
  display: block;
  font-size: 23pt;
  height: 6vh;
  text-align: center;
}
.linkTo2 {
  display: block;
  font-size: 23pt;
  height: 6vh;
  text-align: center;
}

.sidebarElementHeader {
  font-size: 8pt;
  padding-top: -100px;
  margin-top: -100;
}

.sidebarIcon {
  padding-bottom: -100;
  margin-bottom: -100;
}

/*
.svg-inline--fa.fa-w-14 {
    height: .675em;
}

.svg-inline--fa.fa-w-16 {
    height: .85em;
}
.svg-inline--fa.fa-w-12 {
    height: .7em;
}

.svg-inline--fa.fa-w-20 {
    width: 1em;
}
*/

.links {
  color: #455a64;
}

.links:hover {
  text-decoration: none;
  color: #b0bec5;
}

.activeLink {
  color: #fff;
  /*border-right: 2px solid #0fce84;*/
}
.activeLink .sidebarIcon {
  background-color: #19344d;
  border-left: 3px solid #0093ee;
}

.App-logo {
  height: 6vh;
  padding: 0.25vw;
  padding-left: 1vw;
  padding-right: 1.3vw;
  float: left;
}

.checkboxwrapper {
  position: absolute;
  margin-top: 29.5vh;
  right: 31vh;
  display: inline-block;
}

.smsboxwrapper {
  position: absolute;
  margin-top: -7vh;
  display: inline-block;
}

.icheckbox_line-grey {
  border: none;
  border: 2px solid #5c5e61;
  outline: none;
  background: transparent;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.icheckbox_line-grey.hover {
  background: #5c5e61;
}

.icheckbox_line-grey.checked {
  border: none;
  border: 2px solid #0fce84;
  outline: none;
  background: transparent;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.icheckbox_line-grey.checked.hover {
  background: #0fce84;
}

.icheckbox_line-green {
  border: none;
  border: 2px solid #fe6d86;
  outline: none;
  background: transparent;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.icheckbox_line-green.checked {
  border: none;
  border: 2px solid #0fce84;
  outline: none;
  background: transparent;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.icheckbox_line-green.hover {
  border: none;
  border: 2px solid #0fce84;
  outline: none;
  background: #0fce84;
  border: 2px solid #fe6d86;
  outline: none;
  background: #fe6d86;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.icheckbox_line-green.checked.hover {
  border: none;
  border: 2px solid #0fce84;
  outline: none;
  background: #0fce84;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.icheckbox_line-green.checked.disabled,
.iradio_line-green.checked.disabled {
  background: #1c293b;
  border-color: #1c293b;
  color: #8a8a8a;
}
.iradio_line-blue {
  border: none;
  border: 2px solid #5c5e61;
  outline: none;
  background: transparent;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.iradio_line-blue.checked {
  border: none;
  border: 2px solid #2489c5;
  outline: none;
  background: transparent;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.iradio_line-blue.hover {
  background: #2489c5;
  border: 2px solid #2489c5;
}

.iradio_line-pink {
  background: #e0e0e0;
}

.iradio_line-pink.checked {
  background: #bdbdbd;
}

.iradio_line-pink.hover {
  background: #0067a4;
}

.App-byggtorget {
  height: 6vh;
  padding-top: 0.6vh;
  padding-bottom: 0.6vh;
  float: left;
}

.App-logi {
  border-radius: 2px;
  min-width: 50px;
  background-color: #36e136;
  cursor: pointer;
  border: 0;
  min-width: 80px;
  color: #fff;
  font-size: 12px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 8px;
  float: right;
}
.App-logu {
  border-radius: 2px;
  background-color: #e53030;
  cursor: pointer;
  border: 0;
  min-width: 80px;
  max-height: 7vh;
  color: #fff;
  font-size: 12px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 8px;
  float: right;
  position: fixed;
  right: 0;
  top: 0;
  margin-right: 0.25%;
  margin-top: 0.25%;
}
.App-header {
  background-color: #2c405a;
  height: 6vh;
  width: 100vw;
  box-shadow: 0px 5px 8px rgba(22, 25, 27, 0.12);
  z-index: -100000;
  display: block;
  margin-bottom: 1px;
}

.App-intro {
  font-size: large;
}

.fill {
  display: block;
}
.fill .button {
  width: 100%;
}

.printerTypeWrapper {
}

.printerType {
  font-size: 10px;
  padding: 3px 10px 3px 28px;
}

.icheck_line-icon.printerIcon {
  left: 8px;
  font-size: 10px;
}

.addbttn {
  margin-top: 2%;
  margin-bottom: 3%;
  border-radius: 2px;

  background-color: #0fce84;
  cursor: pointer;
  border: 0;
  min-width: 120px;
  color: #fff;
  font-size: 14px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 1%;
  position: center;
}
.appwindow {
  width: 96.6vw;
  height: 93.9vh;
  background-color: #212f41;
}
.dropdownField {
  display: inline-block;
  text-align: center;
  width: 33%;
  margin-top: 1%;
  margin-bottom: 1%;
}

.add-page {
  box-sizing: border-box;
  float: left;
  width: 48vw;
  height: 93.9vh;
  bottom: 0px;
  overflow: hidden;
}
h2 {
  text-align: center;
}
h3 {
  position: absolute;
  float: right;
  right: 68%;
  margin-top: 1.6% !important;
  margin-bottom: 0px;
  text-align: right;
  display: inline;
}

.nameHeader {
  position: absolute;
  float: right;
  right: 68%;
  margin-top: 1.5%;
  margin-bottom: 0px;
  text-align: right;
  display: inline;
  font-size: 1.75rem;
}

.loggheader {
  display: inline-block;
  background-color: white;
  box-sizing: border-box;
  padding: 0.5% 3% 1% 1%;
  border-bottom: 0.1vw solid rgb(167, 167, 167);
  height: 3.5vh;
  width: 50vw;
}
.add-box {
  position: relative;
  top: 0px;
  text-align: left;
  margin-left: 10vh;
  background-color: #212f41;
  width: inherit;
  height: 55.2vh;
  /*margin-right: auto;*/
}
.add-box h2 {
  position: relative;
  padding-top: 3.2vh;
  top: 0px;
  text-align: left;
  margin-left: 21vh;
  width: inherit;
  margin-bottom: 0.5rem;
}
.add-box input,
textarea {
  width: 33%;
  padding: 10px 12px;
  padding-right: 10px;
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1.4px solid #d4d4d4;
  border-radius: 3px;
  outline: none;
  background: #26374c;
}
.add-box input::placeholder,
textarea::placeholder {
  color: #9a9a9a;
}
.add-box select {
  font-size: 16px;
  color: #fff;
  margin-bottom: 12px;
  border: none;
  border-bottom: 1.4px solid #d4d4d4;
  outline: none;
  background: #26374c;
}

.infotekst {
  position: absolute;
  margin-top: 6vh;
  right: 17vh;
  font-size: 15px;
  display: inline-block;
}
.anleggbuttonWrapper {
  position: absolute;
  margin-top: 24.8vh;
  right: 16vh;
  display: inline-block;
}
.icheckbox_line-grey.disabled {
  background: #212f41;
  border-color: rgba(#575757, 0.69);
  color: #8a8a8a;
  opacity: 0.2;
}
.probuttonWrapper {
  position: absolute;
  margin-top: 24.8vh;
  right: 31vh;
  display: inline-block;
}
.probuttonWrapper.pakkeprobutton {
  position: absolute;
  margin-top: 1vh;
  right: 133vh;
  display: inline-block;
}

.ui.selection.dropdown {
  font-size: 16px;
  color: #fff;
  margin-bottom: 12px;
  border: none;
  border-bottom: 1.4px solid #d4d4d4;
  outline: none;
  background: #26374c;
}

.form-check.form-check-inline {
  width: 10.5%;
  padding: 0;
  margin: 0;
}

.checkbox1 {
  display: inline-block;
}

.add-box textarea {
  width: 33%;
  padding: 1%;
  margin-bottom: 0.5%;
  box-sizing: border-box;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #354452;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.retrievedlist-page {
  height: 43vh;
  overflow-y: hidden;
  width: 47.8vw;
}

.list-page {
  float: left;
  height: inherit;
  width: 48.6vw;
  border-left: 0.2vw solid #42556d;
}

.listofitems.itemsretrieved {
  height: 33vh;
  width: 47.82vw;
}

.listofitems {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: 88vh;
  width: 48.7vw;
}

.sortbox {
  width: 50vw;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 11px;
  border-bottom: 2px solid #26374c;
  background: #26374c;
}

.sortbox.sortretrieve {
  width: 47.82vw;
}

.listbox {
  text-align: left;
  padding-top: 1.75%;
  padding-bottom: 1.75%;
  width: 98.6%;
  background-color: #26374c;
  float: right;
  margin-top: 0.6%;
  margin-left: 0.25%;
  margin-right: 0.6%;
  border-radius: 4px;
  border-left: 6px solid #26374c;
  border: 1px solid rgba(255, 255, 255, 0.08);
}
.listbox1 {
  text-align: left;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 99%;
  background-color: #26374c;
  float: right;
  margin-top: 0.5%;
  margin-left: 0.25%;
  margin-right: 0.5%;
  border-radius: 4px;
  border-left: 6px solid #26374c;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.listbox.red {
  border-left: 6px solid #fe6d86;
}

.listbox.orange {
  border-left: 6px solid #f5d718;
}

.listbox.green {
  border-left: 6px solid #0fce84;
}

/* .listofitems li:nth-child(even) .listbox {
  background: #ffffff;
  margin-left: 6px;
  border-bottom: 1px solid #cccccc;
}
*/
.listofitems li {
  list-style-type: none;
}

.boxcount {
  display: inline-block;
  width: 5%;
  margin-left: 1%;
}
.circleMarkred {
  display: inline-block;
  height: 100%;
  font-size: 70%;
  margin-top: 5px;
  width: 2%;
  float: left;
  color: #e53030;
  margin-left: 1%;
}

.circleMarkorange {
  height: 100%;
  font-size: 50%;
  margin-top: 5px;
  font-size: 50%;
  width: 2%;
  float: left;
  color: #ffa500;
  margin-left: 1%;
}

.circleMark {
  width: 2%;
  float: left;
  text-align: left;
  color: rgba(0, 0, 0, 0);
  margin-left: 1%;
}

.boxname {
  text-transform: uppercase;
  display: inline-block;
  width: 32%;
}
.boxtlf {
  display: inline-block;
  width: 19%;
  text-align: center;
}

.boxkolli {
  display: inline-block;
  width: 10%;
  text-align: center;
}

.boxdays {
  display: inline-block;
  width: 10%;
  text-align: center;
}

.comment {
  display: none;
  padding-top: 2%;
  padding-bottom: 1%;
  overflow: hidden;
}

.commentText {
  display: inline-block;
  width: 100%;
  padding-left: 1%;
  margin-right: 1%;
  margin-top: 2%;
}

.commentText.ret {
  padding-left: 1%;
}

.commentHeader.retrieved {
  width: 20%;
  margin-right: 1%;
}

.commentWrapper {
  color: #cccccc;
  display: inline-flex;
  margin-right: 1%;
}

.commentWrapper.retrieved {
  width: 30%;
}

.commentWrapperDesc {
  color: #dedede;
  display: inline-flex;
  align-self: stretch;
  margin-right: 1%;
}

.commentWrapperDesc.projectText {
}

.commentWrapperDesc.addText {
  margin-right: 1%;
}

.fa-pencil-square-o {
  padding-right: 8px;
}

.buttonWrapper {
  width: 20%;
  float: right;
  margin-right: 0.1%;
}

.buttonWrapper2 {
  margin-top: 1%;

  width: 20%;
  float: right;
  margin-right: 0.1%;
}

.insti {
  font-size: 20px;
  margin-left: 0.25%;
  cursor: pointer;
  background-color: #212f41;
}

.showIcon {
  padding-left: 2%;

  margin: 0;
  display: inline-block;
  cursor: pointer;
}

.showIcon.showRet {
  padding-left: 2%;
}

.editIcon {
  cursor: pointer;
}

.editIconWrapper {
  display: inline-block;
  float: left;
}

.editBox {
  width: 100%;
}

.editCommentBox {
  margin: 0;
  width: 100%;
}
.commentHeader {
  float: left;
  display: inline-block;
  width: 19%;
}
.linebetween {
  height: 7vh;
  margin-left: 2%;
  margin-right: 1.6%;
  float: left;
  border: 1px solid rgba(138, 164, 183, 0.2);
}
.linebetweenEksp {
  width: 96%;
  margin-left: 2%;
  float: center;
  border-top: 1px solid rgba(138, 164, 183, 0.2);
}

.descriptionText {
  float: left;
  display: inline-block;
  width: 16%;
  margin-right: 1%;
}

.descriptionText.projectText {
  width: 8%;
}

.descriptionText.addText {
  width: 16%;
}

.removebtn {
  text-align: center;
  float: auto;
  width: 4vw;
  margin-left: 3.3%;
  border-radius: 2px;
  background-color: #0fce84;
  cursor: pointer;
  border: 0px;
  font-size: 14px;
  color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.removebtn.smstrue {
}
.removebtn.smsfalse {
  background-color: rgb(171, 66, 56);
}

.removebtn.save {
  margin-left: 0;
}

.kommentarbox h3 {
  position: absolute;
  float: right;
  right: 68%;
  margin-top: 3% !important;
  margin-bottom: 0px;
  text-align: right;
  display: inline;
}

.radio {
  display: inline-block;
}
.removebtnPopupJA {
  text-align: center;
  float: auto;
  width: 4vw;
  margin-left: 3.3%;
  border-radius: 2px;
  background-color: #0fce84;
  cursor: pointer;
  border: 0px;
  font-size: 14px;
  color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.removebtnPopupNEi {
  text-align: center;
  float: auto;
  width: 4vw;
  margin-left: 3.3%;
  border-radius: 2px;
  background-color: #fe6d86;
  cursor: pointer;
  border: 0px;
  font-size: 14px;
  color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.recover {
  width: 10%;
  background-color: #f5d718;

  text-align: center;
  float: auto;
  margin-left: 2%;
  border-radius: 2px;
  cursor: pointer;
  border: 0px;
  font-size: 14px;
  color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.removebtn.retrieveremove {
  margin-left: 1.5%;
}

.removebtn.feilbtn {
  background-color: #fe6d86;
  width: 1.5vw;
  margin-left: 2%;
}

.removebtn.edit {
  float: left;
}

.removebtn.lars {
  background-color: #e39b2f;
  float: left;
  margin-left: 4%;
}
.removebtn.print {
  margin-left: 4%;
  background-color: #323840;
  float: left;
}

.removebtn.utkjoring {
  margin-left: 4%;
  background-color: rgb(46, 166, 162);
  float: left;
}

.removebtn.utkjoring.fakturer {
  background-color: rgb(133, 63, 93);
  opacity: 0.4;
}

.removebtn.utkjoring.fakturer.fakturerActive {
  background-color: rgb(133, 63, 93);
  opacity: 1;
}

.subComment {
  float: left;
  width: 100%;
  padding-left: 1%;
}
.swal-wide {
  height: 80px !important;
  width: 400px !important;
  font-size: 22px !important;
}

.swal2-title {
  color: #dedede !important;
}

.swal2-content {
  color: #dedede !important;
}
