.pakkeLeft {
  display: block;
  width: 35vw;
  background-color: #212f41;
}

.pakkeLeft.hiden {
  width: 0vw;
  display: none;
}

.hideButton {
  position: fixed;
  cursor: pointer;
  font-size: 2.5em;
  top: 6.3vh;
  padding-left: 2px;
}

.showIcon {
  display: block;
}

.hideIcon {
  display: none;
}

.fillPakke {
  display: block;
  text-align: center;
  background-color: #212f41;
}

.pakkeHeader {
  text-align: center;
  background-color: #212f41;
  margin-bottom: 0;
  padding: 2%;
}

.modalHeader {
  font-size: 20px;
  padding-top: 3%;
  padding-bottom: 1%;
  font-weight: 500;
}

.modalInput {
  width: 100%;
  padding: 2%;
  margin-bottom: 5%;
}

.modalDropdown {
  margin-bottom: 5%;
  width: 100%;
  padding: 2%;
}



.dropdownField.pakkeDrop {
  width: 45%;
  padding-right: 1%;
  margin: 1%;
  margin-top: 0.8%;
  margin-bottom: 0.5%;
  border-radius: 5px;
}
.hideButton{
  color: #354452;
}

/*
  GOOGLE PLACES CSS
*/
.autocomplete-dropdown-container {
  width: 16%;
  margin-left: 9.5%;
  margin-top:-20px;
  position: absolute;
  z-index: 100000;
  border-radius: 1px;
  color: #ffffff;
}

.autocomplete-dropdown-container {
  border: 1px solid black;
}

.autocomplete-dropdown-container:empty {
  border: 0px solid black;
}

.autocomplete-dropdown-container.modalAuto {
  width: 82%;
  margin-left: 1%;
}

.suggestionItem {
  background-color: #ffffff;
  padding-top: 1%;
  padding-bottom: 1%;
}

.suggestionItem.activeSuggestion {
  background-color: #cfcfcf;
}

.pakkeh4 {
  right: 90%;
  position: absolute;
  float: right;
  margin-top: 0.85%;
  margin-bottom: 0px;
  text-align: right;
  display: inline;
}

.first {
  font-size: 1.5rem;
  padding: 0;
  right: 90%;
  position: absolute;
  float: right;
  margin-top: 0.65%;
  margin-bottom: 0px;
  text-align: right;
  display: inline;
}



.inputField.modalPlaces {
  width: 100%;
}

.pakkeRight {
  width: 61.6vw;
}

.pakkeRight.widen {
  width: 100vw;
}

.statusOverview {
  height: 20vh;
  width: inherit;
  display: inline-block;
  background-color: #212f41;
}

.statusOverview.heighten {
  height: 23vh;
}

.totalPakkeStatus {
  display: inline-block;
  box-sizing: border-box;

  text-align: center;
  height: 80%;
  width: 20%;
  padding: 2%;
  margin: 1.5%;
  margin-right: 2.5%;
  margin-left: 2.5%;
  font-size: 8vh;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}

.totalPakkeStatus.activeStatus {
  font-weight: 1000;
  border-bottom: 3px solid white;
}

.totalPakkeStatus.greenStatus {
  background-image: linear-gradient(
    to right top,
    #13900e,
    #12971f,
    #109e2d,
    #0ea539,
    #0bac45
  );
}

.totalPakkeStatus.orangeStatus {
  background-image: linear-gradient(
    to right top,
    #f9690e,
    #f76d1b,
    #f67125,
    #f4752d,
    #f27935
  );
}

.totalPakkeStatus.blueStatus {
  background-image: linear-gradient(
    to right top,
    #004a80,
    #00477a,
    #004576,
    #004271,
    #00416f
  );
}

.totalPakkeStatus.redStatus {
  background-image: linear-gradient(
    to top left,
    #ec334d,
    #ee2e47,
    #ef2942,
    #de3d43,
    #de3d43
  );
}

.sortBox.pakkeSort {
  padding-left: 1.3%;
}

.utkjorbox.status.sortStatus {
  margin-left: 0.7%;
}

.status {
  display: inline-block;
  width: 15%;
  text-align: center;
}

.status.notPack {
  color: #e0e0e0;
}

.status.gettingPack {
  color: #f5d718;
}
.checkboxwrapperpakke{
  display: inline-flex;
  padding: 5px;
}
.smsboxwrapperpakke{
  padding-right: 10px;
}
.smsPakke{
  padding-top: 10px;
}


.status.donePack {
  color: #0fce84;
}

.hideStatus {
  display: none;
}

.listbox1.notPack {
  border-left: 6px solid #e0e0e0;
}

.listbox1.gettingPack {
  border-left: 6px solid #f5d718;
}

.listbox1.donePack {
  border-left: 6px solid #0fce84;
}

.listBox1 {
  text-align: left;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 99%;
  background-color: #26374c;
  float: right;
  margin-top: 0.5%;
  margin-left: 0.25%;
  margin-right: 0.5%;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-left: 6px solid #26374c;
}

.pakkeList {
  overflow-y: auto;
  overflow-x: hidden;
  height: 68.3vh;
  background-color: #212f41;
}

.pakkeLoggWrapper {
  margin-top: 5%;
  width: 100%;
}

.loggHeader {
  display: flex;
  background-color: white;
  box-sizing: border-box;
  padding: 0.5% 3% 0.5% 2%;
  border-bottom: 0.1vw solid rgb(167, 167, 167);
  width: 100%;
}

.pakkeLogg {
  overflow-y: auto;
  height: 27vh;
}

.telephone.logg {
  width: 11%;
}

.order.logg {
  width: 11%;
}

.undoIconWrapper {
  display: inline-block;
  color: #f9690e;
  width: 5%;
  margin-left: 13%;
  font-size: 1vw;
}

.removeIconWrapper.logg {
  display: inline-block;
  width: 5%;
}

.name.logg {
  width: 30%;
}

.telephone.pakke {
  width: 10%;
}

.car {
  display: inline-block;
  width: 3%;
  text-align: center;
}

.who {
  display: inline-block;
  width: 13%;
  text-align: center;
}
.le {
  display: inline-block;
  width: 3%;
  text-align: center;
  color: #4f8bfd;
}

.pri {
  display: inline-block;
  width: 3%;
  text-align: center;
}

.utkjorbox.fromBestilling {
  display: inline-block;
  text-align: center;
  width: 3%;
}

.kolli {
  display: inline-block;
  float: left;
  margin-left: 1.5%;
  width: 6%;
}

.lagttilbox {
  display: inline-block;
  float: left;
  width: 13%;
}
.buttonWrapperpakke {
  width: 30%;
  float: left;
}
.buttonWrapperpakke2 {
  margin-top: 1%;
  width: 30%;
  float: left;

}

.linebetweenPakke {
  height: 7vh;
  margin-left: 1%;
  margin-right: 1%;
  float: left;
  border: 1px solid rgba(138, 164, 183, 0.2);
}

.sendBtn.pakkeStatus {
  margin-left: 2%;
}

.pakkeStatus.notPack {
  background-color: #f9690e;
}

.pakkeStatus.donePack {
  background-color: #1e9519;
}

.sendBtn.deleteBtn.pakke {
  margin-right: 0%;
  width: 20%;
}
.prosjektWrapper {
  width: 15%;
  float: left;
  display: inline-block;
  margin-left: 1%;
}
.deleteBtn.pakke.reset {
  margin-right: 2%;
  width: 23%;
  background-color: rgb(45, 101, 142);
}

.deleteBtn.pakke.pri {
  margin-right: 0%;
  width: 20%;
  background-color: rgb(126, 61, 189);
}

.deleteBtn.pakke.print {
  margin-right: 2%;
  width: 20%;
  background-color: rgb(136, 184, 48);
}

.deleteBtn.pakke.change {
  margin-right: 2%;
  width: 20%;
  background-color: rgb(46, 166, 162);
  opacity: 1;
}
.deleteBtn.pakke.change.changepakke {
  opacity: 0.4;
}

.deleteBtn.pakke.pri.invisible {
  color: rgba(255, 255, 255, 0);
}
