.appWindow {
  width: 100vw;
  height: 93vh;

}

.leftApp {
  width: 45vw;
  height: 93vh;
  float: left;
}

.mapsWrapper {
  width: 100%;
  height: 100%;
}

.mapPin {
  color: black;

  transform: translate(-50%, -50%);
}

.mapPinText {
  color: #fff;
  transform: translateX(-5px);
}

.mapPinIcon {
  color: #e53030;
  display: block;
}

.addPage {
  height: 50%;
  text-align: center;
  background-color: #f1f1f1;
  font-size: 1.4vh;
}
.fillUtkjoring {
  display: block;
}

.appWindow input, textarea {
  width: 45%;
  padding: 10px 12px;
  padding-right: 10px;
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1.4px solid #d4d4d4;
  border-radius: 3px;
  outline: none;
  background: #26374c;
}
.appWindow input::placeholder, textarea::placeholder {
  color: #9a9a9a;
}
.appWindow input:disabled {
  border-bottom: 1.4px solid #3a4252;
  background: #1c293b;
  color: #3a4252;
}
.appWindow input:disabled::placeholder {
  color: #3a4252;
}
.icheckbox_line-green.disabled, .iradio_line-green.disabled {
        background: #fff;
        cursor: default;
    }
.appWindow select:disabled {
  border-bottom: 1.4px solid #3a4252;
  background: #1c293b;
  color: #3a4252;
}

.inputField {
  width: 45%;
  padding: 10px 12px;
  padding-right: 10px;
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1.4px solid #d4d4d4;
  border-radius: 3px;
  outline: none;
  background: #26374c;
}

.addBttn {
  margin-top: 1%;
  border-radius: 2px;
  background-color: #61ba69;
  cursor: pointer;
  border: 0;
  min-width: 120px;
  color: #fff;
  font-size: 14px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 0.5%;
  position: center;
}

.header {
  text-align: center;
  padding: 1% 1% 0% 1%;
}

h4 {
  position: absolute;
  float: right;
  right: 86%;
  margin-top: 0.4%;
  margin-bottom: 0px;
  text-align: right;
  display: inline;
}

.historyPage {
  height: 50%;
}

.historyHeaderWrapper {
  float: left;
  width: 100%;
}

.sendHeader {
  box-sizing: border-box;
  float: left;
  padding: 0.5% 3% 0.5% 3%;
  border-right: 0.1vw solid #506673;
  border-bottom: 0.1vw solid #506673;
  width: 14%;
  cursor: pointer;
}

.historyHeader {
  box-sizing: content-box;
  width: 80%;
  float: left;
  padding: 0.5% 3% 0.5%;
  border-bottom: 0.1vw solid #616c86;
  cursor: pointer;
}

.sortBox.sendSort {
  padding-top: 4.5%;
  padding-bottom: 0.5%;
  margin-top: 0;
  border-bottom: 0.1vw solid #40505a;
  width: 100%;
  padding-right: 0.5%;
}

.sendList {
  height: 90%;
}

.listSend {
  overflow-y: auto;
  overflow-x: hidden;
  height: 34vh;
  background-color: #212f41;
}

.historyElement {
}

.historyInfo {
  display: none;
}

.removeIcon {
  margin-left: 1%;
  color: #e53030;
  font-size: 1vw;
  cursor: pointer;
}

.removIconWrapper {
  margin-left: 1%;
  display: inline-block;
}

.smsIcon {
  display: inline-block;
  margin-left: 10%;
  color: #61ba69;
  font-size: 1vw;
  cursor: pointer;
}

.offSms {
  color: #e53030;
}

.sendMenu {
  padding: 1%;
  border-top: 2px solid #506673;
}

.sendBtn.clear {
  width: 8%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-left: 70%;
  background-color: #e53030;
  text-align: center;
}

.sendBtn.sendAll {
  width: 8%;
  margin-left: 5%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;

  text-align: center;
}

.rightApp {
  width: 51.6vw;
  height: 93vh;
  float: left;
  border-left: 0.3vw solid #42556d;
}

.overview {
  height: 25vh;
  padding-left: 0.25%;
  padding-top: 0.25%;
  background-color: #212f41;
  user-select: none;
}

.place {
  width: 24.45%;
  height: 47%;
  float: left;
  text-align: center;
  font-size: 5vh;
  font-weight: 200;
  background-color: #354452;
  margin: 0.25%;
  color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  opacity: 0.6;
  border-radius: 5px;
}
.place.placeActive {
  color: #fff;
  opacity: 1;
  font-weight: 500;
}
.place:hover {
  cursor: pointer;
}

.statusWrapper {
  font-size: 28px;
  text-align: center;
  margin-top: -0.8vh;
  height: 40%;
}
.statusCount {
  display: inline-block;
  padding-right: 4%;
  padding-left: 4%;
  font-weight: 700;
}

.statusCount.green {
  color: #13900e;
}
.statusCount.orange {
  color: #f9690e;
}
.statusCount.blue {
  color: #4f8bfd;
}
.statusCount.red {
  color: #ec334d;
}

.listPage {
  height: 75vh;
  background-color: #212f41;
}

.listUtkjoring {
  overflow-y: auto;
  overflow-x: hidden;
  height: 63.8vh;
}

.sortBox {
  text-align: left;
  width: 100%;
  background-color: #26374c;
  padding: 2%;
  padding-top: 1%;
  padding-bottom: 0.5%;
  padding-left: 1%;
  border-bottom: 2px solid #26374c;
}

.listBox {
  text-align: left;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  width: 99%;
  background-color: #26374c;
  float: right;
  margin-top: 0.5%;
  margin-left: 0.25%;
  margin-right: 0.5%;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-left: 6px solid #26374c;
}

.circle {
  display: inline-block;
  width: 3%;
}

.orangeCircle {
  color: #f9690e;
}

.greenCircle {
  color: #13900e;
}

.nr {
  display: inline-block;
  width: 5%;
  padding-left: 1%;
}

.name {
  display: inline-block;
  width: 23%;
  text-transform: uppercase;
}

.telephone {
  display: inline-block;
  width: 9%;
  text-align: center;
}

.order {
  display: inline-block;
  width: 10%;
  text-align: center;
}
.order.sortOrder {
  width: 10.5%;
}

.address {
  display: inline-block;
  width: 26%;
  text-align: left;
}

.utkjorbox.address.sortAddress {
  width: 26.5%;
}

.date {
  display: inline-block;
  width: 9%;
  text-align: left;
}

.sendBtn {
  display: inline-block;
  width: 6%;
  text-align: center;
  background-color: #61ba69;
  cursor: pointer;
  border: 0;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 2px;
  font-size: 14px;
}

.sendBtn.utkjor {
  margin-left: 1.5%;
}

.commentTextWrapper {
  width: 20%;
  float: left;
  display: inline-block;
  margin-left: 1%;
}

.sendBtn.deleteBtn {
  background-color: #e53030;
  float: right;
  margin-right: 4.5%;
}

.sendBtn.deleteBtn.utkjoringDelete {
  margin-right: 7.5%;
}

.expandIcon {
  display: inline-block;
  width: 3%;
  margin-left: 1%;
  text-align: center;
  cursor: pointer;
}

li {
  list-style-type: none;
}

.historyList div:nth-child(even).historyGroup {
  background: #ededed;
}

.historyList {
  width: 100%;
  height: 41.5vh;
  overflow-y: auto;
  background: #212f41;
}

.historyGroup {
  width: 100%;
  padding: 1%;
}

.historyDate {
  display: inline-block;
  width: 30%;
}
.historyNumber {
  display: inline-block;
  width: 30%;
}

.historyIcon {
  display: inline-block;
  float: right;
  margin-right: 0.5%;
  cursor: pointer;
}

.historyIcon.mapIcon {
  margin-right: 2%;
}

.historyItemWrapper {
  display: none;
  margin-top: 0.7%;
  padding-top: 0.7%;
  border-top: 3px dotted #b6b6b6;
}

.historyItemElement {
  display: inline-block;
}

.historyItemElement.elementCount {
  width: 5%;
}

.historyItemElement.elementName {
  width: 25%;
}

.historyItemElement.elementTelephone {
  width: 12%;
}

.historyItemElement.elementOrder {
  width: 12%;
}

.historyItemElement.elementAddress {
  width: 30%;
}
