tr:nth-child(even) {
  background: lightgrey;
}
table {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
h1 {
  text-align: center;
}
