.outsideInput {
  float: left;
  width: 35vw;
  height: 93vh;
}
.outsideInput.hiden{
  display:none;
}

.upperInfo {
  height: 37%;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-color: #42556d;
  background-color: #212f41;
  padding-top: 1%;
}

.upperInfoHeader {
  font-size: 150%;
  width: 100%;
  height: 10%;
  /* padding-top: 2%; */
  margin-bottom: 1%;
  text-align: center;
  text-transform: uppercase;
}

.upperInfoFields {
  height: 90%;
}

.outsideInputForm {
  height: 60%;
}

.outsideInputFormWrapper {
  height: 100%;
}

.inputField.outside {
  width: 100%;
  height: 5vh;
}

.outsideInputField {
  width: 40%;
  height: 20%;
  margin-left: 5%;
  margin-bottom: 2%;
  padding: 1%;
  float: left;
}

.outsideInputHeader {
  font-size: 16px;
}

.autocomplete-dropdown-container.outside{
  margin-left:0;
  margin-top:-20px;
  background-color: #2c3742;
  color:white;
}

.suggestionItem {
  background-color: #354452;
}

.activeSuggestion {
  background-color: #4e6072;
}

.outsideButtonWrapper {
  width: 100%;
  display: block;
  height: 20%;
  float: left;
  margin-left: 5%;
  margin-top: 3%;
}

.addBttn.outside {
  display: block;
  margin-top: 3%;
  margin-left: 5%;
}
