.chosenItemField {
  width: 40%;
  height: 18%;
  margin-left: 5%;
  margin-bottom: 2%;
  padding: 1%;
  float: left;
}

.chosenItemHeader {
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 1%;
}

.chosenItemData {
}
